import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import React, {
  useEffect,
  useState,
} from 'react';

import Web3 from 'web3';

import selimAppABI from './yavuzapp.json';
import selimTokenABI from './yavuztoken.json';

const tokenAddress = '0xedEbEcf3a3ECA4Fa33e901F586D28e8c77019F89';
const appAddress = '0x0bb4a9136Bcaa3a93Cc8eaCbf6d3FF5c3e69D77B';
const selimTokenABI1 = selimTokenABI.abi || selimTokenABI;
const selimAppABI1 = selimAppABI.abi || selimAppABI;

function App() {
  const [web3, setWeb3] = useState(null);
  const [account, setAccount] = useState(null);
  const [contract, setContract] = useState(null);
  const [projects, setProjects] = useState([]);
  const [userVote, setUserVote] = useState({});
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    const initWeb3 = async () => {
      if (window.ethereum) {
        const web3Instance = new Web3(window.ethereum);
        setWeb3(web3Instance);
        try {
          const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
          setAccount(accounts[0]);
          setIsConnected(true);
          const appContract = new web3Instance.eth.Contract(selimAppABI1, appAddress);
          setContract(appContract);
          await loadProjects(appContract);
        } catch (err) {
          console.error('Cüzdan bağlantısı hatası: ', err);
        }
      } else {
        alert('Lütfen bir Web3 sağlayıcısı yükleyin.');
      }
    };

    initWeb3();

    if (window.ethereum) {
      window.ethereum.on('accountsChanged', (accounts) => {
        if (accounts.length > 0) {
          setAccount(accounts[0]);
          setIsConnected(true);
        } else {
          setAccount(null);
          setIsConnected(false);
        }
      });
    }
  }, []);

  const loadProjects = async (appContract) => {
    try {
      const projectsData = await appContract.methods.getAllProjects().call();
      console.log(projectsData)
      const formattedProjects = projectsData.map((project, index) => ({
        name: project.name,
        vote: project.vote,
        index: index,
      }));
      setProjects(formattedProjects);
    } catch (err) {
      console.error('Projeleri yükleme hatası: ', err);
    }
  };

  const handleVote = async (projectIndex) => {
    let amount = userVote[projectIndex] || 0;
  
    // Eğer kullanıcı çok küçük bir miktar girerse, minimum işlem miktarını geçerli yapmak için düzeltme yapıyoruz
    const minAmount = 1; // Bu, kullanıcıların gönderebileceği minimum oy miktarıdır (0.000001 token).
  
    // Eğer kullanıcı 1 veya daha küçük bir değer girerse, bunu minimum değeriyle çarpıyoruz
    amount = parseInt(amount) < minAmount ? minAmount : parseInt(amount);
  
    // 6 sıfır eklemek için 10^6 ile çarpma
    amount = amount * 10**6;
  
    if (web3 && contract && account) {
      const tokenContract = new web3.eth.Contract(selimTokenABI1, tokenAddress);
      const tokenBalance = await tokenContract.methods.balanceOf(account).call();
  
      // Yetersiz bakiye kontrolü
      if (amount <= 0 || amount > tokenBalance) {
        alert('Yetersiz bakiye veya geçersiz miktar');
        return;
      }
  
      try {
        const gasPrice = await web3.eth.getGasPrice();
        const gasLimit = 800000;
  
        console.log('Approve işlemi başlatılıyor...');
        const approveTx = await tokenContract.methods.approve(appAddress, amount)
          .send({ from: account, gas: gasLimit, gasPrice });
  
        if (approveTx.status) {
          console.log('Approve işlemi başarılı');
          console.log('Vote işlemi başlatılıyor...');
  
          const voteTx = await contract.methods.addtoProject(projectIndex + 1, amount) // projectIndex + 1 kullanarak id'yi güncelledik
            .send({ from: account, gas: gasLimit, gasPrice });
  
          if (voteTx.status) {
            alert('Oyunuz başarıyla verildi!');
            loadProjects(contract); // Proje listesini güncelle
          } else {
            console.error('Vote işlemi başarısız');
            alert('Vote işlemi başarısız oldu!');
          }
        } else {
          console.error('Approve işlemi başarısız');
          alert('Approve işlemi başarısız oldu!');
        }
      } catch (err) {
        console.error('Oy gönderme hatası:', err);
        alert('Oy gönderme sırasında bir hata oluştu. Lütfen tekrar deneyin.');
      }
    }
  };
  

  const handleConnectWallet = async () => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        setAccount(accounts[0]);
        setIsConnected(true);
      } catch (err) {
        console.error('Cüzdan bağlantı hatası: ', err);
      }
    } else {
      alert('Lütfen bir Web3 sağlayıcısı yükleyin.');
    }
  };

  const handleDisconnect = () => {
    setIsConnected(false);
    setAccount(null);
  };

  return (
    <div className="App">
    <header className="App-header">
      <div className="container text-center">
        <div className="mb-4">
          {!isConnected && (
            <button
              className="btn btn-primary btn-lg"
              onClick={handleConnectWallet}
            >
              Cüzdan Bağla
            </button>
          )}
          {isConnected && (
            <div>
              <p>Cüzdan: {account}</p>
              <button className="btn btn-danger" onClick={handleDisconnect}>
                Cüzdan Bağlantısını Kes
              </button>
            </div>
          )}
        </div>
  
        <div className="row justify-content-center">
          {isConnected && projects.map((project) => (
            <div className="col-md-3 m-2" key={project.index}>
              <div className="card text-center">
                <div className="card-body">
                  <h5 className="card-title">{project.name}</h5>
                  <p className="card-text">Toplam Oy: {project.vote}</p>
                  <input
                    type="number"
                    className="form-control mb-2"
                    placeholder="Oy miktarı"
                    min="0"
                    value={userVote[project.index] || ''}
                    onChange={(e) =>
                      setUserVote({ ...userVote, [project.index]: e.target.value })
                    }
                  />
                  <button
                    className="btn btn-warning w-100"
                    onClick={() => handleVote(project.index)}
                  >
                    VOTE
                  </button>
                </div>
              </div>
            </div>
          ))} 
        </div>
      </div>
    </header>
  </div>
  
  
  );
}

export default App;
